import { Title, Text, Container, Button, Overlay } from "@mantine/core";
import classes from "./HeroImageBackground.module.css";
import { HeaderTransparent } from "../Static/HeaderTransparent";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export function HeroImageBackground() {
    const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <HeaderTransparent />
      </div>

      <Overlay color="#000" opacity={0.85} zIndex={1} />

      <div className={classes.inner}>
        <Title className={classes.title}>
          "Fuel Your Vision. Fund Your Future.”
          <Text component="span" inherit className={classes.highlight}></Text>
          <Text className={classes.bydomax}>By Domax Credit</Text>
        </Title>

        <Container size={740}>
          <Text className={classes.description}>
            {t('home.section1Description')}
          </Text>
        </Container>

        <div className={classes.controls}>
          <Button
            className={classes.control}
            size="lg"
            component={Link}
            to="/contactus"
          >
            {t('common.applyNow')}
          </Button>
        </div>
      </div>
    </div>
  );
}
