import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import WhatsappLogo from '../components/Static/WhatsappLogo';
import { HeaderWhite }  from '../components/Static/HeaderWhite';
import { Footer } from '../components/Static/Footer';
import StillHaveQuestion from '../components/HomePage/StillHaveQuestion';
import Faq from '../components/FaqPage/Faq';

function FaqRealPage() {
  return (
    <MantineProvider>
      <div className="App">
        <HeaderWhite />
        <Faq />
        <StillHaveQuestion />
        <Footer />
      </div>
      <WhatsappLogo />
    </MantineProvider>
  );
}

export default FaqRealPage;
