import React from "react";
import {
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
  Box,
  Checkbox,
  Anchor,
} from "@mantine/core";
import { ContactIconsList } from "./contacticon";
import classes from "./ContactUs.module.css";
import "./ContactUs.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface LinkItem {
  link: string;
  label: string;
}
const pp: LinkItem[] = [{ link: "/privacypolicy", label: "Privacy Policy" }];
const tc: LinkItem[] = [
  { link: "/termsandconditions", label: "Terms & Conditions" },
];
export function ContactUs() {
    const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLinkClick = (link: string) => {
    navigate(link);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  const pppage = pp.map((link) => (
    <span
      key={link.label}
      className={classes.link}
      onClick={() => handleLinkClick(link.link)}
    >
      {link.label}
    </span>
  ));

  const tcpage = tc.map((link) => (
    <span
      key={link.label}
      className={classes.link}
      onClick={() => handleLinkClick(link.link)}
    >
      {link.label}
    </span>
  ));

  const handleFormSubmit = () => {
    const nameInput = document.getElementById("name") as HTMLInputElement;
    const emailInput = document.getElementById("email") as HTMLInputElement;
    const phoneInput = document.getElementById("phone") as HTMLInputElement;
    const messageInput = document.getElementById("message") as HTMLInputElement;
    const checkboxInput = document.getElementById(
      "checkbox"
    ) as HTMLInputElement;

    if (
      !nameInput.value.trim() ||
      !emailInput.value.trim() ||
      !phoneInput.value.trim() ||
      !messageInput.value.trim() ||
      !checkboxInput.checked
    ) {
      alert("Please fill in all the required fields and tick the checkbox.");
      return;
    }

    // Construct the WhatsApp link with the encoded message
    const encodedMessage = encodeURIComponent(
      `Name: ${nameInput.value}\nEmail: ${emailInput.value}\nPhone: ${phoneInput.value}\nMessage: ${messageInput.value}`
    );
    const whatsappLink = `https://api.whatsapp.com/send?phone=60102993668&text=${encodedMessage}`;

    // Open the WhatsApp link
    window.open(whatsappLink, "_blank");

    // Reset form fields if needed
    // nameInput.value = '';
    // emailInput.value = '';
    // phoneInput.value = '';
    // messageInput.value = '';
    // checkboxInput.checked = false;
  };

  return (
    <div className={classes.wrapper}>
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
        <div className={classes.spacing}>
          <Box className={classes.box}>
            <Title className={classes.title}>{t('contactUs.title')}</Title>
            <Text className={classes.description}>
              {t('contactUs.description')}
            </Text>
            <ContactIconsList />
          </Box>
        </div>

        <div className={classes.form}>
          <TextInput
            label={t('contactUs.field1')}
            placeholder={t('contactUs.placeholder1')}
            required
            id="name"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            required
            label={t('contactUs.field2')}
            placeholder={t('contactUs.placeholder2')}
            mt="md"
            id="email"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            required
            label={t('contactUs.field3')}
            placeholder={t('contactUs.placeholder3')}
            mt="md"
            id="phone"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <Textarea
            required
            label={t('contactUs.field4')}
            placeholder={t('contactUs.placeholder4')}
            minRows={4}
            mt="md"
            id="message"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />

          <br />

          <Checkbox
            label={
              <>
                <span className={classes.CustomText}>
                    {t('contactUs.checkboxDescription')}{" "}
                </span>
                <span className={classes.LinkText}>{pppage}</span>
              </>
            }
            id="checkbox"
          />

          <Group justify="flex-end" mt="md">
            <Button
              className={classes.control}
              style={{ fontSize: "14px" }}
              onClick={handleFormSubmit}
            >
              Send message
            </Button>
          </Group>
        </div>
      </SimpleGrid>
    </div>
  );
}
