import { Group, Text } from "@mantine/core";
import classes from "./Footer.module.css";
import myLogo from "../../images/domaxlogowhite.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface LinkItem {
  link: string;
  label: string;
}

export function Footer() {
  const { t } = useTranslation();
  
  const links: LinkItem[] = [
    { link: "/aboutus", label: t('common.aboutUs') },
    { link: "/services", label: t('common.services') },
    { link: "/contactus", label: t('common.contactUs') },
    { link: "/faq", label: t('common.faq') },
  ];
  
  const linkslower: LinkItem[] = [
    { link: "/privacypolicy", label: t('common.privacyPolicy') },
    { link: "/termsandconditions", label:  t('common.termsAndConditions') },
  ];

  const navigate = useNavigate();

  const handleLinkClick = (link: string) => {
    navigate(link);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const items = links.map((link) => (
    <span
      key={link.label}
      className={classes.link}
      onClick={() => handleLinkClick(link.link)}
    >
      {link.label}
    </span>
  ));

  const itemslower = linkslower.map((link) => (
    <span
      key={link.label}
      className={classes.link}
      onClick={() => handleLinkClick(link.link)}
    >
      {link.label}
    </span>
  ));

  return (
    <div>
      {/* Main Footer */}
      <div className={classes.footer}>
        <div className={classes.inner}>
          <div className={classes.logoContainer}>
            <img src={myLogo} alt="My Logo" className={classes.logo} />
          </div>

          <div className={classes.texts}>
            <p>Do The Max.</p>
          </div>

          <div className={classes.spacer} />

          <div className={classes.linksContainer}>
            <Group className={classes.links}>{items}</Group>
          </div>
        </div>
      </div>

      {/*  Footer */}
      <div className={classes.afterFooter}>
        <div className={classes.leftContent}>
          <Text size="xs">{t('footer.companyDescription')}</Text>
        </div>
        <div className={classes.rightContent}>
          <Group style={{ fontSize: "smaller" }}>{itemslower}</Group>
        </div>
      </div>
    </div>
  );
}
