import { Text } from "@mantine/core";
import classes from "./ContactIcons.module.css";
import { ReactNode } from "react";
import { IconBrandWhatsapp } from "@tabler/icons-react";
import { useTranslation } from 'react-i18next';

interface ContactIconProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "title"> {
  title: React.ReactNode;
  description?: React.ReactNode;
  icon?: ReactNode; 
  link?: string; 
}

export function ContactIcon({
  title,
  description,
  icon,
  link,
  ...others
}: ContactIconProps) {
    const { t } = useTranslation();
  const content = (
    <div className={classes.wrapper} {...others}>
      <div className={classes.body}>
        <Text size="xs" className={classes.title}>
          {title}
        </Text>
        {description && (
          <Text className={classes.description}>{description}</Text>
        )}
        {icon &&
          link &&
          title === t('contactUs.sub3Title') && (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.iconLink}
            >
              <div className={classes.icon}>{icon}</div>
            </a>
          )}
      </div>
    </div>
  );

  return content;
}

export function ContactIconsList() {
    const { t } = useTranslation();

    const MOCKDATA = [
        { title: t('contactUs.sub1Title'), description: t('contactUs.sub1Data') },
        {
        title: t('contactUs.sub2Title'),
        description:
            t('contactUs.sub2Data'),
        },
        {
        title: t('contactUs.sub3Title'),
        icon: <IconBrandWhatsapp />,
        link: "https://api.whatsapp.com/send?phone=60102993668&text=",
        },
    ];

  const items = MOCKDATA.map((item, index) => (
    <ContactIcon key={index} {...item} />
  ));
  return <div className={classes.contactContainer}>{items}</div>;
}
