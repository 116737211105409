import { Title, Text, Container, Button, Overlay } from "@mantine/core";
import classes from "./ServicesHero.module.css";
import { HeaderTransparent } from "../Static/HeaderTransparent";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export function ServicesHero() {
    const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <HeaderTransparent />
      </div>

      <Overlay
        color="#000"
        opacity={0.85}
        zIndex={1}
        className={classes.overlay}
      />

      <div className={classes.inner}>
        <Title className={classes.title}>
          {t('services.section1Title1')}
          <Text component="span" inherit className={classes.highlight}></Text>
        </Title>

        <Text size="sm" className={classes.bydomax}>
        {t('services.section1Title2')}
        </Text>

        <Container size={840}>
          <Text className={classes.description}>
            {t('services.section1Description')}
          </Text>
        </Container>

        <div className={classes.controls}>
          <Button
            className={classes.control}
            variant="white"
            size="lg"
            component={Link}
            to="/contactus"
          >
            {t('common.contactUs')}
          </Button>
        </div>
      </div>
    </div>
  );
}
