import { Container, Title, Accordion, Text } from "@mantine/core";
import classes from "./Faq.module.css";
import { useTranslation } from 'react-i18next';

export function Faq() {
    const { t } = useTranslation();

    const domaxFaq = {
    term1: {
        question: t('faq.left2Title'),
        answer: t('faq.left2Description'),
    },
    term2: {
        question: t('faq.left1Title'),
        answer: t('faq.left1Description'),
    },
    term3: {
        question: t('faq.left3Title'),
        answer: (
            <div style={{ whiteSpace: "pre-line" }}>
                {t('faq.left3Description')}
            </div>
        ),
    },
    term4: {
        question: t('faq.left4Title'),
        answer: t('faq.left4Description'),
    },
    term5: {
        question: t('faq.left5Title'),
        answer: t('faq.left5Description')
    },
    term6: {
        question: t('faq.right1Title'),
        answer: t('faq.right1Description')
    },
    term7: {
        question: t('faq.right2Title'),
        answer: t('faq.right2Description')
    },
    term8: {
        question: t('faq.right3Title'),
        answer: t('faq.right3Description')
    },
    term9: {
        question: t('faq.right4Title'),
        answer: t('faq.right4Description')
    },
    term10: {
        question: t('faq.right5Title'),
        answer: t('faq.right5Description')
    },
    // term9: {
    //   question: "Can I use the funds for other purposes?",
    //   answer:
    //     "Yes, once you secure SME financing with Domax, you can use the funds for any legitimate business purpose. Whether you need to invest in new equipment, hire additional staff, or expand your operations, our financing solutions are flexible to meet your needs.",
    // },
    };

  const faqEntries = Object.entries(domaxFaq);
  const splitPoint = Math.ceil(faqEntries.length / 2);
  const firstColumn = faqEntries.slice(0, splitPoint);
  const secondColumn = faqEntries.slice(splitPoint);

  return (
    <div className={classes.container}>
      <Container className={classes.wrapper}>
        <Title className={classes.title}>
          {t('faq.title')}
          <Text className={classes.titledesc}>
          {t('faq.description')}
          </Text>
        </Title>
      </Container>

      <div className={classes["faq-columns"]}>
        <Accordion className={classes["accordion-container"]} multiple>
          {firstColumn.map(([key, value]) => (
            <Accordion.Item key={key} className={classes.item} value={`firstColumn-${key}`}>
              <Accordion.Control className={classes.question}>
                {value.question}
              </Accordion.Control>
              <Accordion.Panel className={classes.answer}>
                {value.answer}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>

        <Accordion className={classes["accordion-container"]} multiple>
          {secondColumn.map(([key, value]) => (
            <Accordion.Item key={key} className={classes.item} value={`secondColumn-${key}`}>
              <Accordion.Control className={classes.question}>
                {value.question}
              </Accordion.Control>
              <Accordion.Panel className={classes.answer}>
                {value.answer}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default Faq;
