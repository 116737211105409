import { Container, Title, Accordion, Text } from "@mantine/core";
import classes from "./FaqPage.module.css";
import { useTranslation } from 'react-i18next';

export function FaqSimple() {
    const { t } = useTranslation();
const domaxFaq = {
    term1: {
      question: t('faq.left2Title'),
      answer: t('faq.left2Description')
    },
    term2: {
        question: t('faq.left5Title'),
        answer: t('faq.left5Description')
    },
    term3: {
        question: t('faq.right1Title'),
        answer: t('faq.right1Description')
    },
    term4: {
      question: t('faq.left3Title'),
      answer: (
        <div style={{ whiteSpace: "pre-line" }}>
            {t('faq.left3Description')}
        </div>
    ),
    },
  };
  
  const faqEntries = Object.entries(domaxFaq);

  return (
    <div>
      <Container size="sm" className={classes.wrapper}>
        <Title className={classes.title}>
          {t('home.section6Title')}
          <Text className={classes.titledesc}>
          {t('home.section6Description')}
          </Text>
        </Title>
      </Container>

      <Accordion className={classes.accordion} multiple>
        {faqEntries.map(([key, value]) => (
          <Accordion.Item key={key} className={classes.item} value={key}>
            <Accordion.Control className={classes.question}>
              {value.question}
            </Accordion.Control>
            <Accordion.Panel className={classes.answer}>
              {value.answer}
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}

export default FaqSimple;
