import { Text, SimpleGrid, Container, rem, Title, Button } from "@mantine/core";
import classes from "./ApplyNow.module.css";
import { Link } from "react-router-dom";
import ProfitIcon from '../../images/profit 1.svg';
import SubmitIcon from '../../images/submit 1.svg';
import DocumentsIcon from '../../images/documents 1.svg';
import { useTranslation } from 'react-i18next';

export function ApplyNow() {
    const { t } = useTranslation();
    const apply = [
    {
        imageUrl: SubmitIcon,
        title: t('home.section4Sub1Title'),
        description: t('home.section4Sub1Description'),
    },
    {
        imageUrl: DocumentsIcon,
        title: t('home.section4Sub2Title'),
        description: t('home.section4Sub2Description'),
    },
    {
        imageUrl: ProfitIcon,
        title: t('home.section4Sub3Title'),
        description: t('home.section4Sub3Description'),
    }
    ];

  return (
    <Container size="full" className={classes.featuresContainer}>
      <Title order={1} className={classes.featuresTitle}>
        {t('home.section4Title')}
      </Title>
      <Text className={classes.featuresDescription}>
        {t('home.section4Description')}
      </Text>
      <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={50}>
        {apply.map((item: any) =>
          <div className={classes.feature}>
            <div className={classes.overlay} />
            <div className={classes.content}>
              <img src={item.imageUrl} alt={item.title} />
              <Text className={classes.title}>{item.title}</Text>
              <Text className={classes.description}>{item.description}</Text>
            </div>
          </div>
        )}
      </SimpleGrid>
      <div className={classes.buttonContainer}>
        <Button
          size="lg"
          radius="xl"
          color="#110d54"
          component={Link}
          to="/contactus"
        >
          {t('common.applyNow')}
        </Button>
      </div>
    </Container>
  );
}
