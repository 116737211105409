import { Text, SimpleGrid, Container, rem, Title } from "@mantine/core";
import classes from "./Features.module.css";
import QuickApprovalIcon from "../../images/documentation 1.svg";
import CompetitiveRatesIcon from "../../images/ranking 1.svg";
import CustomizableSolutionsIcon from "../../images/negotiation 1.svg";
import { useTranslation } from 'react-i18next';

export function Features() {
    const { t } = useTranslation();
    const ft = [
        {
          imageUrl: QuickApprovalIcon,
          title: t('home.section2Sub1Title'),
          description: t('home.section2Sub1Description'),
        },
        {
          imageUrl: CompetitiveRatesIcon,
          title: t('home.section2Sub2Title'),
          description: t('home.section2Sub2Description'),
        },
        {
          imageUrl: CustomizableSolutionsIcon,
          title: t('home.section2Sub3Title'),
          description: t('home.section2Sub3Description'),
        },
      ];

  return (
    <Container size="full" className={classes.featuresContainer}>
      <Title order={1} className={classes.featuresTitle}>
        {t('home.section2Title')}
      </Title>
      <Text className={classes.featuresDescription}>
      {t('home.section2Description')}
      </Text>
      <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={0}>
        {ft.map((item) => (
          <div className={classes.feature}>
            <div className={classes.overlay} />
            <div className={classes.content}>
              <img src={item.imageUrl} alt="picture" className={classes.icon} />
              <Text className={classes.title}>{item.title}</Text>
              <Text className={classes.description}>{item.description}</Text>
            </div>
          </div>
        ))}
      </SimpleGrid>
    </Container>
  );
}
