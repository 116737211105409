import classes from './ServicesSHQ.module.css';
import arrow1 from '../../images/Arrows 1.png';
import arrow2 from '../../images/Arrows 2.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StillHaveQuestion = () => {
    const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <img src={arrow1} alt="Left Arrow" className={classes.leftImage} />
      <div className={classes.content}>
        <h2 className={classes.title}>{t('footer.footerTitle1')}</h2>
        <p className={classes.description}>
            {t('footer.footerDescription1')}
        </p>
        <Link to="/contactus" style={{ textDecoration: 'none' }} className={classes.button}>
            {t('common.contactUs')}
        </Link>   
      </div>
      <img src={arrow2} alt="Right Arrow" className={classes.rightImage} />
    </div>
  );
};

export default StillHaveQuestion;
