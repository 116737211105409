import React, { useState, useEffect } from 'react';
import {
    Group,
    UnstyledButton,
    Divider,
    Box,
    Drawer,
    ScrollArea,
    rem,
    useMantineTheme,
    Burger,
  } from '@mantine/core';
  import myLogo from '../../images/domaxlogo.png'
  import { useDisclosure } from '@mantine/hooks';
  import { Link } from 'react-router-dom';
  import {
    IconWorld,
  } from '@tabler/icons-react';
  import i18n from 'i18next';
  import { useTranslation } from 'react-i18next';
  import classes from './HeaderWhite.module.css';
  export function HeaderWhite() {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const theme = useMantineTheme();

    const [language, setLanguage] = useState(i18n.language || 'en');
    const { t } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language); // Set the language on load
    }, [language]);  

    const toggleLanguage = () => {
      const newLanguage = language === 'en' ? 'bm' : 'en';
      i18n.changeLanguage(newLanguage); // Change language in i18next
      setLanguage(newLanguage); // Update local state
    };
  
    return (
      <Box pb={0}>
        <header className={classes.header}>
          <Group justify="space-between" h="100%">
          <Link to ="/" className={classes.link}>
          <img src={myLogo} alt="Domax Logo" className={classes.link} />
          </Link>
  
          <Group h="100%" gap={0} visibleFrom="sm">
            <Link to="/aboutus" className={classes.link}>
              {t('common.aboutUs')}
            </Link>
            <Link to="/services" className={classes.link}>
              {t('common.services')}
            </Link>
            <Link to="/faq" className={classes.link}>
              {t('common.faq')}
            </Link>
            <Link to="/contactus" className={classes.link}>
              {t('common.contactUs')}
            </Link>
            </Group>
            <Group visibleFrom="sm" className={classes.languageSelector}>
                <UnstyledButton className={classes.link} onClick={toggleLanguage}>
                    <IconWorld style={{ marginRight: '4px' }} size={25} stroke="2" /> {language === 'en' ? 'ENG' : 'BM'}
                </UnstyledButton>
            </Group>
        <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" color='black' />


        </Group>
        </header>
  
        <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        hiddenFrom="sm"
        zIndex={1000000}
        color='black'
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />

          <Link to="/aboutus" className={classes.drawerlink}>
            {t('common.aboutUs')}
          </Link>
          <Link to="/services" className={classes.drawerlink}>
            {t('common.services')}
          </Link>
          <Link to="/faq" className={classes.drawerlink}>
            {t('common.faq')}
          </Link>
          <Link to="/contactus" className={classes.drawerlink}>
            {t('common.contactUs')}
          </Link>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}