import React from "react";
import { Button } from "@mantine/core";
import classes from './WhatsAppLogo.module.css';
import whatsapplogo from '../../images/wa.png';

const whatsappLink = `https://api.whatsapp.com/send?phone=60102993668&text= Hello, I am interested in financial services`;

const WhatsAppLogo: React.FC = () => {
  const handleClick = () => {
    // Open WhatsApp link in a new tab
    window.open(whatsappLink, "_blank");
  };

  return (
    <Button className={classes.whatsappButton} onClick={handleClick}>
      <img
        className={classes.whatsappLogo}
        src={whatsapplogo}
        alt="WhatsApp Logo"
      />
    </Button>
  );
};

export default WhatsAppLogo;
