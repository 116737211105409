// BottomPart.tsx

import { MantineProvider } from "@mantine/core";

import { ServicesFeaturesWhy } from "./ServicesFeaturesWhy";

import { LoanFunction } from "./LoanCalc";

function ServicesBottomPart() {
  return (
    <MantineProvider>
      <ServicesFeaturesWhy />
      <LoanFunction />
    </MantineProvider>
  );
}

export default ServicesBottomPart;
