import classes from "./WantToKnowMore.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const WantToKnowMore = () => {
    const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h2 className={classes.title}>{t('footer.footerTitle2')}</h2>
        <p className={classes.description}>
            {t('footer.footerDescription2')}
        </p>
        <Link to="/contactus" className={classes.button}>
            {t('common.contactUs')}
        </Link>
      </div>
    </div>
  );
};

export default WantToKnowMore;
