import React, { useState } from "react";
import {
  Text,
  Title,
  TextInput,
  Button,
  Group,
  Select,
  Container,
} from "@mantine/core";

import classes from "./LoanCalc.module.css";
import { useTranslation } from 'react-i18next';

function formatNumberWithCommas(number: number | string): string {
  return Number(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function calculateLoanRepaymentRuleOf78(
  loanAmount: number,
  flatRate: number,
  repaymentPeriod: number
) {
  const monthlyInterestRate = flatRate / 100;
  const totalInterestExpense =
    loanAmount * monthlyInterestRate * repaymentPeriod;
  const totalRepayment = loanAmount + totalInterestExpense;

  let sum_of_digits = 0;
  for (let i = 1; i <= repaymentPeriod; i++) {
    sum_of_digits += i;
  }

  const repaymentSchedule = [];
  let outstandingPrincipal = loanAmount;

  for (let month = 1; month <= repaymentPeriod; month++) {
    const interest_fraction = (repaymentPeriod - month + 1) / sum_of_digits;
    const interestPayment = totalInterestExpense * interest_fraction;
    const principalPayment = totalRepayment / repaymentPeriod - interestPayment;

    outstandingPrincipal -= principalPayment;

    repaymentSchedule.push({
      "No. of instalment": month,
      "Instalment amount (mth)":
        Math.round((interestPayment + principalPayment) * 100) / 100,
      "Interest payment": Math.round(interestPayment * 100) / 100,
      "Principal payment": Math.round(principalPayment * 100) / 100,
      "Outstanding principal": Math.round(outstandingPrincipal * 100) / 100,
    });
  }
  const monthlyInstallment =
    Math.round((totalRepayment / repaymentPeriod) * 100) / 100;

  return {
    "Total Repayment": Math.round(totalRepayment * 100) / 100,
    "Total Interest Expense": Math.round(totalInterestExpense * 100) / 100,
    "Monthly Installment": monthlyInstallment,
    "Repayment Schedule": repaymentSchedule,
  };
}

export function LoanFunction() {
    const { t } = useTranslation();
  const [totalRepayments, setTotalRepayment] = useState("");
  const [monthlyInstallment, setMonthlyInstallment] = useState("");
  const [totalInterest, setTotalInterest] = useState("");

  const [repaymentSchedule, setRepaymentSchedule] = useState<any[]>([]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const inputId = event.target.id;
    const inputValue = event.target.value;

    const numericValue = parseFloat(inputValue.replace(/,/g, ""));
    const isValidNumber = !isNaN(numericValue);

    const formattedValue = isValidNumber
      ? formatNumberWithCommas(numericValue)
      : "";

    event.target.value = formattedValue;

    switch (inputId) {
      case "desiredLoanAmount":
        setTotalRepayment("");
        setMonthlyInstallment("");
        setTotalInterest("");
        setRepaymentSchedule([]);
        if (isValidNumber) {
          // Update desiredLoanAmount state only if the value is a valid number
          // ...
        }
        break;

      // Add cases for other inputIds as needed

      default:
        break;
    }
  };

  const handleCalculate = () => {
    const desiredLoanAmountElement = document.getElementById(
      "desiredLoanAmount"
    ) as HTMLInputElement | null;

    const repaymentPeriodElement = document.getElementById(
      "repaymentPeriod"
    ) as HTMLSelectElement | null;

    const interestRateElement = document.getElementById(
      "interestRate"
    ) as HTMLInputElement | null;

    const desiredLoanAmount = desiredLoanAmountElement
      ? parseFloat(desiredLoanAmountElement.value.replace(/,/g, ""))
      : NaN;

    const repaymentPeriod = repaymentPeriodElement
      ? parseInt(repaymentPeriodElement.value, 10)
      : NaN;

    const interestRate = interestRateElement
      ? parseFloat(interestRateElement.value)
      : NaN;

    if (
      isNaN(desiredLoanAmount) ||
      isNaN(interestRate) ||
      isNaN(repaymentPeriod)
    ) {
      alert("Please enter valid numerical values for all fields.");
      return;
    }

    const result = calculateLoanRepaymentRuleOf78(
      desiredLoanAmount,
      interestRate,
      repaymentPeriod
    );

    setRepaymentSchedule(result["Repayment Schedule"]);
    setTotalRepayment(formatNumberWithCommas(result["Total Repayment"]));
    setMonthlyInstallment(
      formatNumberWithCommas(result["Monthly Installment"])
    );
    setTotalInterest(formatNumberWithCommas(result["Total Interest Expense"]));
  };

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.leftColumn}>
          <Container>
            <Title className={classes.title}>{t('services.section4Title1')}</Title>
            <Text className={classes.by}>{t('services.section4Title2')}</Text>
            <div className={classes.solidLine}></div>
            <Text className={classes.description} mt="sm" mb={5}>
              {t('services.section4sub1Left')} : <br></br>RM {monthlyInstallment}
            </Text>
            <Text className={classes.description} mt="sm" mb={5}>
            {t('services.section4sub2Left')} : <br></br>RM {totalRepayments}
            </Text>
            <Text className={classes.description} mt="sm" mb={5}>
            {t('services.section4sub3Left')} : <br></br>RM {totalInterest}
            </Text>

            <Text className={classes.illus} mt="sm" mb={5}>
              {t('services.section4DisclaimerText')}<br /> {t('services.section4Disclaimer')}
            </Text>
          </Container>
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.form}>
            <TextInput
              label={t('services.section4sub1Right')}
              placeholder={t('common.amount')}
              id="desiredLoanAmount"
              classNames={{
                input: classes.input,
                label: classes.inputLabel,
              }}
              onChange={handleInputChange}
            />

            <Select
              data={[
                { value: "6", label: "6 months" },
                { value: "7", label: "7 months" },
                { value: "8", label: "8 months" },
                { value: "9", label: "9 months" },
                { value: "10", label: "10 months" },
                { value: "11", label: "11 months" },
                { value: "12", label: "12 months" },
                { value: "13", label: "13 months" },
                { value: "14", label: "14 months" },
                { value: "15", label: "15 months" },
                { value: "16", label: "16 months" },
                { value: "17", label: "17 months" },
                { value: "18", label: "18 months" },
              ]}
              label={t('services.section4sub2Right')}
              placeholder={t('common.pleaseSelect')}
              id="repaymentPeriod"
              classNames={{
                input: classes.input,
                label: classes.inputLabel,
              }}
            />

            <TextInput
              label={t('services.section4sub3Right')}
              placeholder={t('common.amount')}
              id="interestRate"
              classNames={{
                input: classes.input,
                label: classes.inputLabel,
              }}
            />

            <Group justify="flex-end" mt="md">
              <Button className={classes.control} onClick={handleCalculate}>
                {t('common.calculate')}
              </Button>
            </Group>
          </div>
        </div>
        {repaymentSchedule.length > 0 && (
          <div className={classes.tableContainer}>
            <h2>{t('services.section6Title')}</h2>
            <table className={classes.repaymentTable}>
              <thead>
                <tr>
                  <th>{t('services.section6Column1')}</th>
                  <th>{t('services.section6Column2')}</th>
                  <th>{t('services.section6Column3')}</th>
                  <th>{t('services.section6Column4')}</th>
                  <th>{t('services.section6Column5')}</th>
                </tr>
              </thead>
              <tbody>
                {repaymentSchedule.map((row, index) => (
                  <tr key={index}>
                    <td>{row["No. of instalment"]}</td>
                    <td>{row["Instalment amount (mth)"]}</td>
                    <td>{row["Interest payment"]}</td>
                    <td>{row["Principal payment"]}</td>
                    <td>{row["Outstanding principal"]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
