import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import WhatsappLogo from '../components/Static/WhatsappLogo';
import { AboutUsHero } from '../components/AboutUsPage/AboutUs';
import { FeaturesTitle } from '../components/AboutUsPage/WhoAreWe';
import { Footer } from '../components/Static/Footer';
import  WantToKnowMore from '../components/AboutUsPage/WantToKnowMore';
import { HeaderWhite }  from '../components/Static/HeaderWhite';


function AboutUsPage() {
  return (
    <MantineProvider>
      <div className="App">
        <HeaderWhite />
        <AboutUsHero />
        <FeaturesTitle />
        <WantToKnowMore />
        <Footer />
      </div>
      <WhatsappLogo />
    </MantineProvider>
  );
}

export default AboutUsPage;
