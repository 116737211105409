import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, MantineProvider } from '@mantine/core';
import App from './App'; // Replace with your App component path
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

const theme = createTheme({
  // Your custom theme overrides go here
});

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <MantineProvider theme={theme}>
            <App />
        </MantineProvider>
    </I18nextProvider>,
    document.getElementById('root')
);
