import { useState, useEffect } from "react";
import { Title, SimpleGrid, Text, Grid } from "@mantine/core";
import classes from "./WhoAreWe.module.css";
import WhoAreWe_Arrow from "../../images/WhoAreWe_Arrow.png";
import { useTranslation } from 'react-i18next';

const Slideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const slideshowImages = getSlideshowImages();

  useEffect(() => {
    if (slideshowImages.length === 0) {
      console.error(
        "No slideshow images found. Check the folder and filenames."
      );
      return;
    }

    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % slideshowImages.length
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [slideshowImages.length]);

  if (slideshowImages.length === 0) {
    return (
      <div>No slideshow images found. Check the folder and filenames.</div>
    );
  }

  return (
    <img
      src={slideshowImages[currentImageIndex]}
      alt={`Slide ${currentImageIndex + 1}`}
      className={classes.images}
    />
  );
};

// Function to get the list of slideshow images
const getSlideshowImages = () => {
  return [
    "image_aboutus.png",
    // Add more images as needed
  ].map((imageName) => require(`../../slideshow/${imageName}`));
};

export function FeaturesTitle() {
    const { t } = useTranslation();
    const features = [
        {
          Image: WhoAreWe_Arrow,
          title: t('aboutUs.section2Sub1Title'),
          description: (
            <ul>
                <li>
                    <div>
                    {t('aboutUs.section2Sub1Description')}
                    </div>
                </li>
            </ul>
          ),
        },
        {
          Image: WhoAreWe_Arrow,
          title: t('aboutUs.section2Sub2Title'),
          description: (
            <ul>
                <li>
                    <div>
                        {t('aboutUs.section2Sub2Description')}
                    </div>
                </li>
            </ul>
            )
        },
        {
          Image: WhoAreWe_Arrow,
          title: t('aboutUs.section2Sub3Title'),
          description: (
            <ul>
                <li>
                    <div>
                        {t('aboutUs.section2Sub3Description')}
                    </div>
                </li>
            </ul>
            )
        },
      ];

  const items = features.map((feature) => (
    <div className={classes.feature}>
      <div className={classes.content}>
        <Text className={classes.destitle}>
          <img src={feature.Image} className={classes.logo} />
          {feature.title}
        </Text>
        <br></br>
        <Text className={classes.description}>{feature.description}</Text>
        <br></br>
      </div>
    </div>
  ));

  return (
    <div className={classes.wrapper}>
      <Grid gutter={80}>
        <Grid.Col span={{ base: 12, md: 5 }} className={classes.slider}>
          <Slideshow />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <SimpleGrid cols={{ base: 1, md: 1 }} spacing={15}>
            {items}
          </SimpleGrid>
        </Grid.Col>
      </Grid>
    </div>
  );
}
