import { Text, SimpleGrid, Container, rem, Button } from "@mantine/core";
import classes from "./ServicesFeaturesWhy.module.css";
import coffee from "../../images/mancalculator.png";
import { Link } from "react-router-dom"; //
import { useTranslation } from 'react-i18next';

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  image?: string;
  title?: string;
  description?: string | JSX.Element;
  button?: { text: string; link: string };
}

function ServicesFeatureWhy({
  image,
  title,
  description,
  button,
  className,
  ...others
}: FeatureProps) {
  return (
    <div className={classes.feature} {...others}>
      <div className={classes.overlay} />
      <div className={classes.content}>
        {image && (
          <div className={classes.left}>
            <img src={image} alt={title} className={classes.icon} />
          </div>
        )}
        <div className={classes.right}>
          {title && <Text className={classes.title}>{title}</Text>}
          {description && (
            <Text className={classes.description}>{description}</Text>
          )}
          <div className={classes.buttonContainer}>
            {button && (
              <Button
                size="sm"
                className={classes.button}
                component={Link}
                to={button.link}
              >
                {button.text}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export function ServicesFeaturesWhy() {
    const { t } = useTranslation();

  const mockdata = [
    {
      image: coffee,
    },
    {
      title: t('services.section3Title'),
      description: (
        <div>
          {t('services.section3Description')}
        </div>
      ),
      button: {
        text: t('common.applyNow'),
        link: "/contactus",
      },
    },
  ];

  const items = mockdata.map((item, index) => (
    <ServicesFeatureWhy {...item} key={index} />
  ));

  return (
    <Container mt={0} mb={0} size="full" className={classes.featuresContainer}>
      <SimpleGrid cols={{ base: 1, sm: 2, md: 2, lg: 2 }} spacing={10}>
        {items}
      </SimpleGrid>
    </Container>
  );
}
