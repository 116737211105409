import { MantineProvider } from "@mantine/core";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@mantine/core/styles.css";
import MainPage from "./NavLink/MainPage";
import AboutUsPage from "./NavLink/AboutUsPage";
import ServicesPage from "./NavLink/ServicesPage";
import ContactUsPage from "./NavLink/ContactPage";
import FaqRealPage from "./NavLink/FaqRealPage";
import Term from "./components/ContactPage/term";
import Privacy from "./components/ContactPage/privacy";
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <MantineProvider>
            <div className="App">
                <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/contactus" element={<ContactUsPage />} />
                    <Route path="/aboutus" element={<AboutUsPage />} />
                    <Route path="/faq" element={<FaqRealPage />} />
                    <Route path="/services" element={<ServicesPage />} />
                    <Route path="/termsandconditions" element={<Term />} />
                    <Route path="/privacypolicy" element={<Privacy />} />
                </Routes>
                </BrowserRouter>
            </div>
            </MantineProvider>
        </I18nextProvider>
    );
}

export default App;
