import { Text, SimpleGrid, Container, rem, Title } from "@mantine/core";
import classes from "./ServicesFeatures.module.css";
import { useTranslation } from 'react-i18next';

export function ServicesFeatures() {
    const { t } = useTranslation();

    const featuresData = [
        {
          imageUrl: require("../../images/documentation 1.svg").default,
          title: t('services.section2Sub1Title'),
          description: t('services.section2Sub1Description'),
        },
        {
          imageUrl: require("../../images/ranking 1.svg").default,
          title: t('services.section2Sub2Title'),
          description: t('services.section2Sub2Description'),
        },
        {
          imageUrl: require("../../images/negotiation 1.svg").default,
          title: t('services.section2Sub3Title'),
          description: t('services.section2Sub3Description'),
        },
      ];
      
  return (
    <Container mt={0} mb={0} size="full" className={classes.featuresContainer}>
      <Title order={1} className={classes.featuresTitle}>
        {t('services.section2Title')}
      </Title>
      <Text className={classes.featuresDescription}>
        {t('services.section2Description')}
      </Text>
      <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={0}>
        {featuresData.map((item) => (
          <div className={classes.feature}>
            <div className={classes.overlay} />
            <div className={classes.content}>
              <img
                src={item.imageUrl}
                alt={item.title}
                className={classes.icon}
              />
              <Text fz="lg" mb="xs" mt={5} className={classes.title}>
                {item.title}
              </Text>
              <Text fz="sm" mb="xs" className={classes.description}>
                {item.description}
              </Text>
            </div>
          </div>
        ))}
      </SimpleGrid>
    </Container>
  );
}
