import { Title, Text, Container } from "@mantine/core";
import classes from "./AboutUs.module.css";
import { useTranslation } from 'react-i18next';

export function AboutUsHero() {
    const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.inner}>
        <Title className={classes.title}>{t('aboutUs.section1Title')}</Title>

        <Container size={1100}>
          <Text className={classes.description}>
            {t('aboutUs.section1Description')}
          </Text>
        </Container>
      </div>
    </div>
  );
}
