import { useState } from "react";
import {
  Paper,
  Text,
  Title,
  Avatar,
  Badge,
  useMantineTheme,
  rem,
  Button,
} from "@mantine/core";
import classes from "./CustomerStories.module.css";
import { useTranslation } from 'react-i18next';

interface CardProps {
  stars: number;
  comments: string;
  customerName: string;
  customerPosition: string;
}

function Card({ stars, comments, customerName, customerPosition }: CardProps) {
  const renderStars = (count: number) => {
    const starElements = [];
    for (let i = 0; i < count; i++) {
      starElements.push(
        <Badge variant="transparent" color="orange" size="xl" key={i}>
          ★
        </Badge>
      );
    }
    return starElements;
  };

  return (
    <div className={classes.page}>
      <Paper shadow="md" p="xl" radius="lg" className={classes.card}>
        <div className={classes.stars}>{renderStars(stars)}</div>
        <Text className={classes.comments}>{comments}</Text>
        <div className={classes.avatar}>
          <Avatar
            size="sm"
            radius="lg"
            src="/avatar.png"
            alt="Customer Avatar"
          />
          <div>
            <Title order={4} className={classes.name}>
              {customerName}
            </Title>
            <Text className={classes.position}>{customerPosition}</Text>
          </div>
        </div>
      </Paper>
    </div>
  );
}

const cs = [
  {
    stars: 5,
    comments:
      "Domax's financing solutions have been a game-changer for our business.",
    customerName: "John Doe",
    customerPosition: "CEO",
  },
  {
    stars: 5,
    comments:
      "Thanks to Domax, we were able to expand our operations and increase our revenue.",
    customerName: "Jane Smith",
    customerPosition: "CFO",
  },
  {
    stars: 5,
    comments:
      "Domax's financing options revolutionized our growth strategy. Grateful for their support.",
    customerName: "Sarah Johnson",
    customerPosition: "CEO",
  },
  {
    stars: 5,
    comments:
      "Domax's financial solutions provided the boost we needed. Exceptional service!",
    customerName: "Emily White",
    customerPosition: "Founder",
  },
  {
    stars: 5,
    comments:
      "Domax's tailored financing solutions fueled our progress. Highly recommend their expertise.",
    customerName: "Alex Reynolds",
    customerPosition: "CEO",
  },
  {
    stars: 5,
    comments:
      "Domax played a pivotal role in our success story. Their financing is a game-changer.",
    customerName: "David Turner",
    customerPosition: "CFO",
  },
];

export function CustomerStories() {
    const { t } = useTranslation();
  const theme = useMantineTheme();
  const cardsPerPage = 2;
  const totalCards = cs.length;
  const totalPages = Math.ceil(totalCards / cardsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage + 1 > totalPages ? 1 : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) =>
      prevPage - 1 < 1 ? totalPages : prevPage - 1
    );
  };

  const startCardIndex = (currentPage - 1) * cardsPerPage;
  const endCardIndex = Math.min(currentPage * cardsPerPage, totalCards);
  const displayedCards = cs
    .slice(startCardIndex, endCardIndex)
    .map((item, index) => (
      <div key={index} className={classes.item}>
        <Card
          stars={item.stars}
          comments={item.comments}
          customerName={item.customerName}
          customerPosition={item.customerPosition}
        />
      </div>
    ));

  const indicators = Array.from({ length: totalPages }, (_, index) => (
    <div
      key={index}
      className={classes.pageIndicators}
      style={{
        width: rem(8),
        height: rem(8),
        borderRadius: "50%",
        backgroundColor:
          currentPage === index + 1
            ? theme.colors.orange[6]
            : theme.colors.gray[4],
        margin: `0 ${rem(4)}`,
      }}
    />
  ));

  return (
    <div className={classes.page}>
      <div className={classes.title}>
        <Title order={2}>{t('home.section5Title')}</Title>
      </div>
      <div>
        <Text className={classes.text}>
          {t('home.section5Description')}
        </Text>
      </div>
      <div className={classes.displayedCards}>{displayedCards}</div>
      <div className={classes.footer}>
        <div className={classes.indicators}>{indicators}</div>
        <div className={classes.arrows}>
          <Button variant="transparent" onClick={goToPrevPage}>
            {/* Placeholder for left arrow */}
            &lt;
          </Button>
          <Button variant="transparent" onClick={goToNextPage}>
            {/* Placeholder for right arrow */}
            &gt;
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CustomerStories;
